import { usernameMaxLength } from './username-validators/username-max-length/username-max-length';
import { usernameMinLength } from './username-validators/username-min-length/username-contain-min-length';
import { usernameContainsWhiteSpace } from './username-validators/username-not-contain-whitespace/username-not-contain-whitespace';
import { usernameContainsOnlyValidCharacters } from './username-validators/username-only-contains-valid-characters/username-only-contains-valid-characters';

export function isUsernameValid(username: string) {
  return (
    usernameMaxLength(username) &&
    usernameMinLength(username) &&
    !usernameContainsWhiteSpace(username) &&
    usernameContainsOnlyValidCharacters(username)
  );
}

import { inject, Injectable } from '@angular/core';

import { catchError, mergeMap, NEVER, Observable, switchMap } from 'rxjs';

import { AuthenticationRepository } from '../../../authentication';
import type { UserProfile } from '../../entities/user-profil.type';
import { ProfileRepository } from '../../repositories/profile/profile.repository';

@Injectable({
  providedIn: 'root',
})
export class ProfileApplicationService {
  #authenticationRepository: AuthenticationRepository = inject(
    AuthenticationRepository,
  );

  #profileRepository: ProfileRepository = inject(ProfileRepository);

  approveDeleteAccountToken(token: string): Observable<void> {
    return this.#profileRepository.approveDeleteAccountToken(token);
  }

  deleteAccount(token: string): Observable<void> {
    return this.#profileRepository
      .deleteProfile(token)
      .pipe(
        switchMap(
          (): Observable<void> =>
            this.#authenticationRepository.deleteAuthenticationTokens(),
        ),
      );
  }

  isNotificationsActivated(): Observable<boolean> {
    return this.#profileRepository.isPushNotificationsEnabled();
  }

  disableNotifications(pushToken: string): Observable<void> {
    return this.#authenticationRepository.getAuthenticationTokens().pipe(
      mergeMap(
        (): Observable<void> =>
          this.#profileRepository.deletePushNotificationsToken(pushToken),
      ),
      catchError((): Observable<never> => NEVER),
    );
  }

  enableNotifications(pushToken: string): Observable<void> {
    return this.#profileRepository.addPushNotificationsToken(pushToken);
  }

  getProfile(): Observable<UserProfile> {
    return this.#profileRepository.getProfile();
  }

  initializeProfileDeletion(): Observable<void> {
    return this.#profileRepository.initDeleteProfile();
  }

  setNewProfilePassword(
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string,
  ): Observable<void> {
    return this.#profileRepository
      .setNewPassword(oldPassword, newPassword, passwordConfirmation)
      .pipe(
        switchMap(
          (): Observable<void> =>
            this.#authenticationRepository.deleteAuthenticationTokens(),
        ),
      );
  }
}

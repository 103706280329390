import type { TokenValidationErrors } from '../../profile/entities/token-validation-errors.type';

export class ApproveCommunicationChannelTokenError
  implements TokenValidationErrors
{
  readonly tokenContainsSpacesOrTabs!: boolean;

  readonly tokenForCommunicationChannelApprovalBelongsToAlreadyApprovedCommunicationChannel!: boolean;

  readonly tokenForCommunicationChannelApprovalHasExpired!: boolean;

  readonly tokenForCommunicationChannelApprovalIsValidButApprovalProcessFailed!: boolean;

  readonly tokenForCommunicationChannelApprovalNotFound!: boolean;

  readonly tokenIsEmpty!: boolean;

  readonly tokenIsNotAString!: boolean;

  readonly tokenForPasswordResetBelongsToDeletedUser!: boolean;

  readonly tokenForPasswordResetHasExpired!: boolean;

  readonly tokenForPasswordResetNotFound!: boolean;

  constructor(errors: TokenValidationErrors) {
    Object.assign(this, errors);
  }
}

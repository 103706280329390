import type { PasswordValidationErrors } from './password-validation-errors.type';

import type { ConfirmPasswordValidationErrors } from './confirm-password-validation-errors.typ';

export class ResetPasswordErrors
  implements PasswordValidationErrors, ConfirmPasswordValidationErrors
{
  readonly passwordConfirmationDiffersFromPassword!: boolean;

  readonly passwordConfirmationIsNotAString!: boolean;

  readonly passwordContainsNoMandatorySpecialCharacters!: boolean;

  readonly passwordContainsSpacesOrTabs!: boolean;

  readonly passwordDoesNotContainANumber!: boolean;

  readonly passwordDoesNotContainLowercaseCharacters!: boolean;

  readonly passwordDoesNotContainUppercaseCharacters!: boolean;

  readonly passwordIsEmpty!: boolean;

  readonly passwordIsNotaString!: boolean;

  readonly passwordIsSameAsCurrentPassword!: boolean;

  readonly passwordIsSimilarToEmailOrUsername!: boolean;

  readonly passwordIsTooLong!: boolean;

  readonly passwordIsTooShort!: boolean;

  constructor(
    errors: PasswordValidationErrors & ConfirmPasswordValidationErrors,
  ) {
    Object.assign(this, errors);
  }
}

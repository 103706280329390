import type { Observable } from 'rxjs';

import type { AuthenticationTokens } from '../entities/authentication-tokens';

export abstract class UserRepository {
  abstract extendSession(): Observable<AuthenticationTokens>;

  abstract login(
    username: string,
    password: string,
    keepLoggedIn: boolean,
  ): Observable<AuthenticationTokens>;

  abstract logout(): Observable<void>;

  abstract register(data: {
    readonly email: string;
    readonly username: string;
    readonly password: string;
    readonly passwordConfirmation: string;
    readonly locale: string;
    readonly termsOfUse: boolean;
    readonly privacy: boolean;
  }): Observable<void>;

  abstract retrieveUsername(email: string): Observable<void>;
}

import { type Observable } from 'rxjs';

import { type UserProfile } from '../../entities/user-profil.type';

export abstract class ProfileRepository {
  abstract addPushNotificationsToken(token: string): Observable<void>;

  abstract getProfile(): Observable<UserProfile>;

  abstract setNewPassword(
    oldPassword: string,
    newPassword: string,
    passwordConfirmation: string,
  ): Observable<void>;

  abstract initDeleteProfile(): Observable<void>;

  abstract isPushNotificationsEnabled(): Observable<boolean>;

  abstract deleteProfile(token: string): Observable<void>;

  abstract deletePushNotificationsToken(token: string): Observable<void>;

  abstract approveDeleteAccountToken(token: string): Observable<void>;
}

import { PasswordValidationErrors } from './password-validation-errors.type';
import { CurrentPasswordValidationErrors } from './current-password-validation-errors.type';
import { ConfirmPasswordValidationErrors } from './confirm-password-validation-errors.typ';

export class NewPasswordErrors
  implements
    PasswordValidationErrors,
    CurrentPasswordValidationErrors,
    ConfirmPasswordValidationErrors
{
  readonly currentPasswordIsNotAString!: boolean;

  readonly currentPasswordIsNotCorrect!: boolean;

  readonly passwordConfirmationDiffersFromPassword!: boolean;

  readonly passwordConfirmationIsNotAString!: boolean;

  readonly passwordContainsNoMandatorySpecialCharacters!: boolean;

  readonly passwordContainsSpacesOrTabs!: boolean;

  readonly passwordDoesNotContainANumber!: boolean;

  readonly passwordDoesNotContainLowercaseCharacters!: boolean;

  readonly passwordDoesNotContainUppercaseCharacters!: boolean;

  readonly passwordIsEmpty!: boolean;

  readonly passwordIsNotaString!: boolean;

  readonly passwordIsSameAsCurrentPassword!: boolean;

  readonly passwordIsSimilarToEmailOrUsername!: boolean;

  readonly passwordIsTooLong!: boolean;

  readonly passwordIsTooShort!: boolean;

  constructor(
    errors: PasswordValidationErrors &
      CurrentPasswordValidationErrors &
      ConfirmPasswordValidationErrors,
  ) {
    Object.assign(this, errors);
  }
}

import { inject, Injectable } from '@angular/core';

import { PLATFORM_PREFERRED_LANGUAGES } from '@mbeon-pwa/common';

import { map, type Observable, tap, throwError } from 'rxjs';

import { LanguageRepository } from '../../repositories/language/language.repository';
import { triggerDomainEvent } from '../../../events';
import { languageChanged } from '../../events';
import { SupportedLanguage } from '../../types/supported-languages.type';

@Injectable({
  providedIn: 'root',
})
export class LanguageApplicationService {
  static readonly fallbackLanguage: SupportedLanguage = 'en_GB';

  static readonly supportedLanguages: readonly SupportedLanguage[] = [
    'ar_AE',
    'de_DE',
    'en_GB',
    'ru_RU',
    'uk_UA',
    'fa_IR',
  ];

  readonly #languageRepository: LanguageRepository = inject(LanguageRepository);

  readonly #platformPreferredLanguages: readonly string[] = inject(
    PLATFORM_PREFERRED_LANGUAGES,
  );

  getAvailableLanguages(): readonly SupportedLanguage[] {
    return Array.from(LanguageApplicationService.supportedLanguages);
  }

  getDefaultLanguage(): Observable<SupportedLanguage> {
    return this.#languageRepository
      .getDefaultLanguage()
      .pipe(
        map(
          (language: SupportedLanguage | undefined): SupportedLanguage =>
            language ?? this.#getFallbackLanguage(),
        ),
      );
  }

  isRtlLanguage(languageCode: SupportedLanguage): boolean {
    return ['ar_AE', 'fa_IR'].includes(languageCode);
  }

  setLanguage(languageCode: SupportedLanguage): Observable<void> {
    if (LanguageApplicationService.supportedLanguages.includes(languageCode)) {
      return this.#languageRepository.setDefaultLanguage(languageCode).pipe(
        tap({
          next: (): void => triggerDomainEvent(languageChanged),
        }),
      );
    }

    // TODO
    return throwError(() => '');
  }

  #getFallbackLanguage(): SupportedLanguage {
    if (this.#platformPreferredLanguages) {
      for (const platformPreferredLanguage of this
        .#platformPreferredLanguages) {
        for (const supportedLanguage of LanguageApplicationService.supportedLanguages) {
          const preparedPlatformLangString = platformPreferredLanguage.replace(
            '-',
            '_',
          );
          if (supportedLanguage.startsWith(preparedPlatformLangString)) {
            return supportedLanguage;
          }
        }
      }
    }

    return LanguageApplicationService.fallbackLanguage;
  }
}

import { inject, Injectable } from '@angular/core';

import {
  type Observable,
  switchMap,
  tap,
  finalize,
  exhaustMap,
  of,
  catchError,
} from 'rxjs';

import { UserRepository } from '../../repositories/user.repository';
import { AuthenticationRepository } from '../../repositories/authentication.repository';
import { AuthenticationTokens } from '../../entities/authentication-tokens';
import { triggerDomainEvent } from '../../../events';
import { userAuthenticated, userSignedOut } from '../../events';

@Injectable({
  providedIn: 'root',
})
export class LoginApplicationService {
  readonly #userRepository: UserRepository = inject(UserRepository);

  readonly #authenticationRepository: AuthenticationRepository = inject(
    AuthenticationRepository,
  );

  login(
    username: string,
    password: string,
    keepLoggedIn: boolean,
  ): Observable<void> {
    return this.#userRepository.login(username, password, keepLoggedIn).pipe(
      switchMap((tokens: AuthenticationTokens): Observable<void> => {
        return this.#authenticationRepository.saveAuthenticationTokens(tokens);
      }),
      tap({
        next: (): void => {
          triggerDomainEvent(userAuthenticated);
        },
      }),
    );
  }

  logout(): Observable<void> {
    return this.#userRepository.logout().pipe(
      catchError((): Observable<void> => of(undefined)),
      exhaustMap(
        (): Observable<void> =>
          this.#authenticationRepository.deleteAuthenticationTokens(),
      ),
      finalize((): void => {
        triggerDomainEvent(userSignedOut);
      }),
    );
  }

  getForgottenUsername(email: string): Observable<void> {
    return this.#userRepository.retrieveUsername(email);
  }
}

import { inject, Injectable } from '@angular/core';

import { map, type Observable, tap } from 'rxjs';

import { triggerDomainEvent } from '../../../events';
import { consultantSetFavorite, consultantUnSetFavorite } from '../../events';
import type { Consultant } from '../../entities/consultant.type';
import type { ConsultantFilter } from '../../entities/consultant-filter.type';
import type { ConsultantPreview } from '../../entities/consultant-preview.type';
import type { ConsultationAttachment } from '../../entities/consultation-attachment.type';
import type { FavoriteConsultant } from '../../entities/favorite-consultant.type';
import { ConsultationFilesRepository } from '../../repositories/consultation-files.repository';
import { ConsultantRepository } from '../../repositories/consultant.repository';

@Injectable({
  providedIn: 'root',
})
export class ConsultantApplicationService {
  readonly #consultantRepository: ConsultantRepository =
    inject(ConsultantRepository);

  readonly #consultationFilesRepository: ConsultationFilesRepository = inject(
    ConsultationFilesRepository,
  );

  getConsultantProfile(consultantId: string): Observable<Consultant> {
    return this.#consultantRepository.getConsultant(consultantId).pipe();
  }

  getFavoriteConsultants(): Observable<readonly FavoriteConsultant[]> {
    return this.#consultantRepository.getFavoriteConsultants();
  }

  setConsultantFavorite(consultantId: string): Observable<void> {
    return this.#consultantRepository.setConsultantFavorite(consultantId).pipe(
      tap({
        next: () => {
          triggerDomainEvent(consultantSetFavorite);
        },
      }),
    );
  }

  unSetConsultantFavorite(consultantId: string): Observable<void> {
    return this.#consultantRepository
      .unSetConsultantFavorite(consultantId)
      .pipe(
        tap({
          next: () => {
            triggerDomainEvent(consultantUnSetFavorite);
          },
        }),
      );
  }

  getSharedFilesWithConsultant(
    consultantId: string,
  ): Observable<readonly ConsultationAttachment[]> {
    return this.#consultationFilesRepository
      .getConsultationAttachments(consultantId)
      .pipe(
        map(
          (
            files: readonly ConsultationAttachment[],
          ): readonly ConsultationAttachment[] =>
            files.filter(
              (file: ConsultationAttachment): boolean => !file.isVoiceMessage,
            ),
        ),
      );
  }

  getFilteredConsultants(
    filter: ConsultantFilter,
  ): Observable<readonly ConsultantPreview[]> {
    return this.#consultantRepository.getFilteredConsultants(filter);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GlobalMessage } from '../entities/global-message.type';

@Injectable()
export abstract class UserInfoRepository {
  abstract getGlobalMessage(): Observable<GlobalMessage>;

  abstract setGlobalMessageWasSeen(id: string): Observable<void>;

  abstract getSeenGlobalMessageIds(): Observable<string>;
}

import { pwContainsNumbers } from './password-validators/password-contains-numbers/password-contain-numbers';
import { pwContainsLargeLetters } from './password-validators/password-contain-large-letters/password-contain-large-letters';
import { pwMaxLength } from './password-validators/password-max-length/password-max-length';
import { pwMinLength } from './password-validators/password-min-length/password-contain-min-length';
import { pwContainsWhiteSpace } from './password-validators/password-not-contain-whitespace/password-not-contain-whitespace';
import { pwContainsSmallLetters } from './password-validators/password-contains-small-letters/password-contain-small-letters';
import { pwContainsSpecialCharacters } from './password-validators/password-contain-special-characters/password-contain-special-characters';

export function isPasswordValid(pw: string) {
  return (
    pwContainsNumbers(pw) &&
    pwContainsLargeLetters(pw) &&
    pwContainsSmallLetters(pw) &&
    pwMaxLength(pw) &&
    pwMinLength(pw) &&
    !pwContainsWhiteSpace(pw) &&
    pwContainsSpecialCharacters(pw)
  );
}

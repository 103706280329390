import { type Observable } from 'rxjs';

import type { SupportedLanguage } from '../../types/supported-languages.type';

export abstract class LanguageRepository {
  abstract getDefaultLanguage(): Observable<SupportedLanguage | undefined>;

  abstract setDefaultLanguage(
    languageCode: SupportedLanguage,
  ): Observable<void>;
}

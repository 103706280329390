export enum IllegalConsultationFileReason {
  IllegalSize,
  IllegalType,
}

export class IllegalConsultationFileError extends Error {
  constructor(readonly reason: IllegalConsultationFileReason) {
    super();
  }
}

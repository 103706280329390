import type { Observable } from 'rxjs';

import { FavoriteConsultant } from '../entities/favorite-consultant.type';

import type { Consultant } from '../entities/consultant.type';
import type { ConsultantFilter } from '../entities/consultant-filter.type';
import type { ConsultantPreview } from '../entities/consultant-preview.type';

export abstract class ConsultantRepository {
  abstract getFavoriteConsultants(): Observable<FavoriteConsultant[]>;

  abstract getConsultant(consultantId: string): Observable<Consultant>;

  abstract setConsultantFavorite(consultantId: string): Observable<void>;

  abstract unSetConsultantFavorite(consultantId: string): Observable<void>;

  abstract getFilteredConsultants(
    consultantFilter?: ConsultantFilter,
  ): Observable<ConsultantPreview[]>;
}

import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

export const PLATFORM_PREFERRED_LANGUAGES: InjectionToken<readonly string[]> =
  new InjectionToken('PREFERRED_LANGUAGE', {
    providedIn: 'platform',
    factory: (): readonly string[] => {
      const platform: object = inject(PLATFORM_ID);

      if (isPlatformBrowser(platform)) {
        return window.navigator.languages;
      }

      return [];
    },
  });

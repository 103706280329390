import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

export interface PlatformIdentifier {
  readonly isBrowser: boolean;
  readonly isServer: boolean;
}

export const PLATFORM_IDENTIFIER: InjectionToken<PlatformIdentifier> =
  new InjectionToken('PLATFORM_IDENTIFIER', {
    providedIn: 'platform',
    factory(): PlatformIdentifier {
      const platformId: object = inject(PLATFORM_ID);

      return {
        isBrowser: isPlatformBrowser(platformId),
        isServer: isPlatformServer(platformId),
      };
    },
  });

import * as i0 from '@angular/core';
import { DestroyRef, inject, signal, Injector, isSignal, computed, Injectable, assertInInjectionContext } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { select, createAccumulationObservable, createSideEffectObservable, safePluck, isKeyOf } from '@rx-angular/state/selections';
import { Subscription, isObservable, EMPTY } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
function createSignalStateProxy(state$, stateFn) {
  const destroyRef = inject(DestroyRef);
  const signalState = {};
  return new Proxy(signalState, {
    get(target, p) {
      let _signal = target[p];
      if (!_signal) {
        const val = stateFn(p);
        _signal = signal(val);
        target[p] = _signal;
        state$.pipe(select(p), takeUntilDestroyed(destroyRef)).subscribe(val => _signal.set(val));
      }
      return _signal;
    },
    has(target, prop) {
      return !!target[prop];
    },
    ownKeys(target) {
      return [...Reflect.ownKeys(target)];
    },
    getOwnPropertyDescriptor(target, key) {
      return {
        enumerable: true,
        configurable: true
      };
    },
    set() {
      return true;
    }
  });
}

/**
 * @description
 * RxState is a light-weight reactive state management service for managing local state in angular.
 *
 * @example
 * Component({
 *   selector: 'app-stateful',
 *   template: `<div>{{ state$ | async | json }}</div>`,
 *   providers: [RxState]
 * })
 * export class StatefulComponent {
 *   readonly state$ = this.state.select();
 *
 *   constructor(private state: RxState<{ foo: string }>) {}
 * }
 *
 * @docsCategory RxState
 * @docsPage RxState
 */
class RxState {
  subscription = new Subscription();
  accumulator = createAccumulationObservable();
  effectObservable = createSideEffectObservable();
  injector = inject(Injector);
  signalStoreProxy;
  /**
   * @description
   * The unmodified state exposed as `Observable<T>`. It is not shared, distinct or gets replayed.
   * Use the `$` property if you want to read the state without having applied {@link stateful} to it.
   */
  $ = this.accumulator.signal$;
  /**
   * @internal
   */
  constructor() {
    this.subscription.add(this.subscribe());
  }
  /**
   * @internal
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /**
   * @description
   *
   * Allows to customize state accumulation function.
   * This can be helpful to implement deep updates and tackle other immutability problems in a custom way.
   * @example
   *
   * ```typescript
   * const myAccumulator = (state: MyState, slice: Partial<MyState>) => deepCopy(state, slice);
   *
   * this.state.setAccumulator(myAccumulator);
   * ```
   */
  setAccumulator(accumulatorFn) {
    this.accumulator.nextAccumulator(accumulatorFn);
  }
  /** @internal **/
  get(...keys) {
    const hasStateAnyKeys = Object.keys(this.accumulator.state).length > 0;
    if (!!keys && keys.length) {
      return safePluck(this.accumulator.state, keys);
    } else {
      return hasStateAnyKeys ? this.accumulator.state : undefined;
    }
  }
  /**
   * @internal
   */
  set(keyOrStateOrProjectState, stateOrSliceProjectFn) {
    if (typeof keyOrStateOrProjectState === 'object' && stateOrSliceProjectFn === undefined) {
      this.accumulator.nextSlice(keyOrStateOrProjectState);
      return;
    }
    if (typeof keyOrStateOrProjectState === 'function' && stateOrSliceProjectFn === undefined) {
      this.accumulator.nextSlice(keyOrStateOrProjectState(this.accumulator.state));
      return;
    }
    if (isKeyOf(keyOrStateOrProjectState) && typeof stateOrSliceProjectFn === 'function') {
      const state = {};
      state[keyOrStateOrProjectState] = stateOrSliceProjectFn(this.accumulator.state);
      this.accumulator.nextSlice(state);
      return;
    }
    throw new Error('wrong params passed to set');
  }
  /**
   * @internal
   */
  connect(keyOrInputOrSlice$, projectOrSlices$, projectValueFn) {
    let inputOrSlice$;
    if (!isKeyOf(keyOrInputOrSlice$)) {
      if (isObservable(keyOrInputOrSlice$)) {
        inputOrSlice$ = keyOrInputOrSlice$;
      } else {
        // why can't typescript infer the correct type?
        inputOrSlice$ = toObservable(keyOrInputOrSlice$, {
          injector: this.injector
        });
      }
    }
    const key = !inputOrSlice$ && isKeyOf(keyOrInputOrSlice$) ? keyOrInputOrSlice$ : null;
    if (projectValueFn === undefined && projectOrSlices$ === undefined && inputOrSlice$) {
      this.accumulator.nextSliceObservable(inputOrSlice$);
      return;
    }
    let slices$ = null;
    let stateReducer;
    if (projectOrSlices$) {
      if (isObservable(projectOrSlices$)) {
        slices$ = projectOrSlices$;
      } else if (isSignal(projectOrSlices$)) {
        slices$ = toObservable(projectOrSlices$, {
          injector: this.injector
        });
      } else {
        stateReducer = projectOrSlices$;
      }
    }
    if (inputOrSlice$ && projectValueFn === undefined && stateReducer !== undefined) {
      const slice$ = inputOrSlice$.pipe(map(v => stateReducer(this.get(), v)));
      this.accumulator.nextSliceObservable(slice$);
      return;
    }
    if (projectValueFn === undefined && key && slices$) {
      const slice$ = slices$.pipe(map(value => ({
        ...{},
        [key]: value
      })));
      this.accumulator.nextSliceObservable(slice$);
      return;
    }
    if (typeof projectValueFn === 'function' && key && slices$) {
      const slice$ = slices$.pipe(map(value => ({
        ...{},
        [key]: projectValueFn(this.get(), value)
      })));
      this.accumulator.nextSliceObservable(slice$);
      return;
    }
    throw new Error('wrong params passed to connect');
  }
  /**
   * @internal
   */
  select(...args) {
    return this.accumulator.state$.pipe(select(...args));
  }
  /**
   * @description
   * Returns a signal of the given key. It's first value is determined by the
   * current keys value in RxState. Whenever the key gets updated, the signal
   * will also be updated accordingly.
   */
  signal(k) {
    return this.signalStoreProxy[k];
  }
  /**
   * @description
   * Lets you create a computed signal based off of multiple keys stored in RxState.
   */
  computed(fn) {
    return computed(() => {
      return fn(this.signalStoreProxy);
    });
  }
  /** @internal */
  computedFrom(...ops) {
    return toSignal(this.select(...ops), {
      injector: this.injector,
      requireSync: true
    });
  }
  /**
   * @description
   * Manages side-effects of your state. Provide an `Observable<any>` **side-effect** and an optional
   * `sideEffectFunction`.
   * Subscription handling is done automatically.
   *
   * @example
   * // Directly pass an observable side-effect
   * const localStorageEffect$ = changes$.pipe(
   *  tap(changes => storeChanges(changes))
   * );
   * state.hold(localStorageEffect$);
   *
   * // Pass an additional `sideEffectFunction`
   *
   * const localStorageEffectFn = changes => storeChanges(changes);
   * state.hold(changes$, localStorageEffectFn);
   *
   * @param {Observable<S>} obsOrObsWithSideEffect
   * @param {function} [sideEffectFn]
   */
  hold(obsOrObsWithSideEffect, sideEffectFn) {
    const sideEffect = obsOrObsWithSideEffect.pipe(catchError(e => EMPTY));
    if (typeof sideEffectFn === 'function') {
      this.effectObservable.nextEffectObservable(sideEffect.pipe(tap(sideEffectFn)));
      return;
    }
    this.effectObservable.nextEffectObservable(sideEffect);
  }
  /**
   * @internal
   */
  subscribe() {
    const subscription = new Subscription();
    subscription.add(this.accumulator.subscribe());
    subscription.add(this.effectObservable.subscribe());
    this.signalStoreProxy = createSignalStateProxy(this.$, this.get.bind(this));
    return subscription;
  }
  /** @nocollapse */
  static ɵfac = function RxState_Factory(t) {
    return new (t || RxState)();
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: RxState,
    factory: RxState.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RxState, [{
    type: Injectable
  }], () => [], null);
})();

/**
 * @description
 * Functional way to setup state management with RxState. It's a wrapper around RxState that automatically get
 *   destroyed.
 *
 * @example
 * ```ts
 * import { rxState } from '@rx-angular/state';
 *
 * Component({})
 * export class FooComponent {
 *  readonly state = rxState<{ count: number }>(({ set }) => set({ count: 0 }));
 * }
 * ```
 *
 * @param setupFn
 * @returns RxState instance
 *
 *
 *
 * @docsCategory RxState
 * @docsPage RxState
 *
 */
function rxState(setupFn) {
  assertInInjectionContext(rxState);
  const legacyState = new RxState();
  const destroyRef = inject(DestroyRef);
  destroyRef.onDestroy(() => legacyState.ngOnDestroy());
  const state = {
    get: legacyState.get.bind(legacyState),
    set: legacyState.set.bind(legacyState),
    connect: legacyState.connect.bind(legacyState),
    select: legacyState.select.bind(legacyState),
    signal: legacyState.signal.bind(legacyState),
    computed: legacyState.computed.bind(legacyState),
    computedFrom: legacyState.computedFrom.bind(legacyState),
    $: legacyState.$,
    setAccumulator: legacyState.setAccumulator.bind(legacyState)
  };
  setupFn?.(state);
  return state;
}

/**
 * Generated bundle index. Do not edit.
 */

export { RxState, rxState };

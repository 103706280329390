import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserInfoRepository } from '../../repositories/user-info-repository';
import { GlobalMessage } from '../../entities/global-message.type';

@Injectable({
  providedIn: 'root',
})
export class UserInfoApplicationService {
  readonly #messageRepository: UserInfoRepository = inject(UserInfoRepository);

  getGlobalMessage(): Observable<GlobalMessage> {
    return this.#messageRepository.getGlobalMessage();
  }

  setGlobalMessageSeen(id: string): Observable<void> {
    return this.#messageRepository.setGlobalMessageWasSeen(id);
  }

  getSeenGlobalMessageIds(): Observable<string> {
    return this.#messageRepository.getSeenGlobalMessageIds();
  }
}

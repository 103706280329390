import { InjectionToken } from '@angular/core';

import { Observable, Subject, filter, map } from 'rxjs';

type DomainEvent = symbol;

const events$: Subject<DomainEvent> = new Subject();

export type OnDomainEvent = (events: DomainEvent) => Observable<void>;

export function onDomainEvent(event: DomainEvent): Observable<void> {
  return events$.pipe(
    filter((domainEvent: DomainEvent): boolean => domainEvent === event),
    map((): void => undefined),
  );
}

export function triggerDomainEvent(event: DomainEvent): void {
  return events$.next(event);
}

export const ON_DOMAIN_EVENT: InjectionToken<OnDomainEvent> =
  new InjectionToken('DOMAIN_EVENT$', {
    providedIn: 'platform',
    factory(): OnDomainEvent {
      return onDomainEvent;
    },
  });

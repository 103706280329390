import { inject, Injectable } from '@angular/core';

import {
  catchError,
  exhaustMap,
  filter,
  forkJoin,
  map,
  mergeMap,
  type Observable,
  of,
  tap,
  throwError,
} from 'rxjs';

import type { Consultant } from '../../entities/consultant.type';
import type { Consultation } from '../../entities/consultation.type';
import type { ConsultationAttachment } from '../../entities/consultation-attachment.type';
import type { ConsultationMessage } from '../../entities/consultation-message.type';
import type { ConsultationStatistics } from '../../entities/consultation-statistics.type';
import { ConsultationFilesRepository } from '../../repositories/consultation-files.repository';
import { ConsultationRepository } from '../../repositories/consultation.repository';
import {
  ConsultationUpdate,
  ConsultationUpdateAcknowledgment,
  ConsultationUpdateMessage,
} from '../../entities/consultation-update.type';
import {
  UnableToProlongAuthentication,
  userSessionExpired,
} from '../../../authentication';
import { triggerDomainEvent } from '../../../events';

export interface MessagePayloadAttachment {
  readonly name: string;
  readonly data: Blob;
}

export interface MessagePayload {
  readonly attachments?: readonly MessagePayloadAttachment[];
  readonly message?: string;
  readonly voiceMessage?: Blob;
}

@Injectable({
  providedIn: 'root',
})
export class ConsultationApplicationService {
  readonly #consultationRepository: ConsultationRepository = inject(
    ConsultationRepository,
  );

  readonly #consultationFilesRepository: ConsultationFilesRepository = inject(
    ConsultationFilesRepository,
  );

  addMessageToConsultation(
    consultant: Consultant,
    payload: MessagePayload,
  ): Observable<void> {
    const consultationAttachments: Observable<ConsultationAttachment>[] = [];

    if (payload?.attachments && payload.attachments.length > 0) {
      for (const attachment of payload.attachments) {
        consultationAttachments.push(
          this.#consultationFilesRepository.addNewFile(
            consultant.id,
            attachment.data,
            false,
          ),
        );
      }
    }

    if (payload.voiceMessage) {
      consultationAttachments.push(
        this.#consultationFilesRepository.addNewFile(
          consultant.id,
          payload.voiceMessage,
          true,
        ),
      );
    }

    const consultationAttachments$: Observable<
      readonly ConsultationAttachment[]
    > =
      consultationAttachments.length > 0
        ? forkJoin(consultationAttachments)
        : of([]);

    return consultationAttachments$.pipe(
      mergeMap(
        (
          consultationAttachments: readonly ConsultationAttachment[],
        ): Observable<void> => {
          return this.#consultationRepository.addToConsultation(
            consultant.username,
            {
              message: payload.message,
              attachments: consultationAttachments,
            },
          );
        },
      ),
      tap({
        next: (): void => {
          // ignore errors
          this.#consultationRepository
            .reportNewMessage(consultant.id)
            .subscribe({});
        },
      }),
    );
  }

  getConsultationMessages(
    consultant: Consultant,
    lastMessageDate?: Date,
  ): Observable<readonly ConsultationMessage[]> {
    let source$: Observable<void>;

    if (!lastMessageDate) {
      source$ = this.#consultationRepository.sendConsultationPresence(
        consultant.username,
      );
    } else {
      source$ = of(undefined);
    }

    return source$.pipe(
      exhaustMap(
        (): Observable<readonly ConsultationMessage[]> =>
          this.#consultationRepository.getConsultationMessages(
            consultant.username,
            {
              toDate: lastMessageDate,
              limit: 50,
            },
          ),
      ),
      tap({
        next: (): void => {
          this.#consultationRepository
            .sendConsultationAcknowledgement(consultant.id)
            .subscribe({
              error: (): void => {
                // ignore because site effect
              },
            });
        },
      }),
    );
  }

  getConsultationMessageUpdates(
    consultant: Consultant,
  ): Observable<ConsultationMessage> {
    return this.#consultationRepository
      .getConsultationUpdates(consultant.username)
      .pipe(
        filter(
          (
            consultationUpdate: ConsultationUpdate,
          ): consultationUpdate is ConsultationUpdateMessage =>
            consultationUpdate.type === 'message',
        ),
        map(
          (
            consultationUpdate: ConsultationUpdateMessage,
          ): ConsultationMessage => consultationUpdate.message,
        ),
      );
  }

  getConsultationAcknowledgementUpdates(
    consultant: Consultant,
  ): Observable<Date> {
    return this.#consultationRepository
      .getConsultationUpdates(consultant.username)
      .pipe(
        filter(
          (
            consultationUpdate: ConsultationUpdate,
          ): consultationUpdate is ConsultationUpdateAcknowledgment =>
            consultationUpdate.type === 'acknowledgement',
        ),
        map(
          (acknowledgment: ConsultationUpdateAcknowledgment): Date =>
            acknowledgment.timestamp,
        ),
      );
  }

  getConsultationStatistics(
    consultant: Consultant,
  ): Observable<ConsultationStatistics> {
    return this.#consultationRepository.getConsultationStatistics({
      id: consultant.id,
      username: consultant.username,
    });
  }

  getConsultations(): Observable<readonly Consultation[]> {
    return this.#consultationRepository.getUserConsultations().pipe(
      catchError((error: unknown): Observable<never> => {
        if (error instanceof UnableToProlongAuthentication) {
          triggerDomainEvent(userSessionExpired);
        }

        return throwError((): unknown => error);
      }),
    );
  }

  sendConsultationAcknowledgement(consultant: Consultant): Observable<void> {
    return this.#consultationRepository.sendConsultationAcknowledgement(
      consultant.id,
    );
  }
}

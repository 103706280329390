import type { PasswordValidationErrors } from './password-validation-errors.type';
import type { UsernameValidationErrors } from './username-validation-errors.type';
import type { EmailValidationErrors } from './email-validation-errors.type';

export class AccountDataErrors
  implements
    PasswordValidationErrors,
    UsernameValidationErrors,
    EmailValidationErrors
{
  readonly emailAddressContainsInvalidString!: boolean;

  readonly emailAddressContainsNotAllowedCharacters!: boolean;

  readonly emailAddressContainsSpacesOrTabs!: boolean;

  readonly emailAddressDnsCheckFailed!: boolean;

  readonly emailAddressIsAlreadyInUse!: boolean;

  readonly emailAddressIsEmpty!: boolean;

  readonly emailAddressIsNotAString!: boolean;

  readonly emailAddressIsNotRfcCompliant!: boolean;

  readonly emailAddressIsTooLong!: boolean;

  readonly emailAddressIsTooShort!: boolean;

  readonly passwordContainsNoMandatorySpecialCharacters!: boolean;

  readonly passwordContainsSpacesOrTabs!: boolean;

  readonly passwordDoesNotContainANumber!: boolean;

  readonly passwordDoesNotContainLowercaseCharacters!: boolean;

  readonly passwordDoesNotContainUppercaseCharacters!: boolean;

  readonly passwordIsEmpty!: boolean;

  readonly passwordIsNotaString!: boolean;

  readonly passwordIsSameAsCurrentPassword!: boolean;

  readonly passwordIsSimilarToEmailOrUsername!: boolean;

  readonly passwordIsTooLong!: boolean;

  readonly passwordIsTooShort!: boolean;

  readonly usernameContainsNotAllowedCharacters!: boolean;

  readonly usernameContainsSpacesOrTabs!: boolean;

  readonly usernameIsAlreadyInUse!: boolean;

  readonly usernameIsEmpty!: boolean;

  readonly usernameIsNotAString!: boolean;

  readonly usernameIsSimilarToEmail!: boolean;

  readonly usernameIsTooLong!: boolean;

  readonly usernameIsTooShort!: boolean;

  constructor(
    errors: PasswordValidationErrors &
      UsernameValidationErrors &
      EmailValidationErrors,
  ) {
    Object.assign(this, errors);
  }
}

import type { Observable } from 'rxjs';

import type { ConsultationMessage } from '../entities/consultation-message.type';
import type { ConsultationStatistics } from '../entities/consultation-statistics.type';
import type { ConsultationUpdate } from '../entities/consultation-update.type';
import { ConnectionStatus } from '../enums/connection-status.enum';
import { Consultation } from '../entities/consultation.type';

export interface ChatMessageFilter {
  readonly reverseConversation?: boolean;
  readonly fromDate?: Date;
  readonly order?: 'asc' | 'desc';
  readonly toDate?: Date;
  readonly limit?: number;
}

export type ConsultationPayloadMessage = {
  readonly message: string;
};

export type ConsultationPayloadAttachment = {
  readonly attachments: readonly {
    readonly id: string;
    readonly isVoiceMessage: boolean;
    readonly mimeType: string;
    readonly name: string;
    readonly size: number;
    readonly url: string;
  }[];
};

export type ConsultationPayload =
  | ConsultationPayloadMessage
  | ConsultationPayloadAttachment
  | (ConsultationPayloadMessage & ConsultationPayloadAttachment);

export abstract class ConsultationRepository {
  abstract addToConsultation(
    consultantUsername: string,
    payload: ConsultationPayload,
  ): Observable<void>;

  abstract getConnectionStatus(): Observable<ConnectionStatus>;

  abstract getConsultationMessages(
    consultantUsername: string,
    filter?: ChatMessageFilter,
  ): Observable<readonly ConsultationMessage[]>;

  abstract getConsultationStatistics(consultant: {
    readonly id: string;
    readonly username: string;
  }): Observable<ConsultationStatistics>;

  abstract getConsultationUpdates(
    consultantUsername: string,
  ): Observable<ConsultationUpdate>;

  abstract getUserConsultations(): Observable<readonly Consultation[]>;

  abstract reportNewMessage(consultantId: string): Observable<void>;

  abstract sendConsultationAcknowledgement(
    consultantId: string,
  ): Observable<void>;

  abstract sendConsultationPresence(contactUserName: string): Observable<void>;
}

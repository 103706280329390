import type { Observable } from 'rxjs';

import type { ConsultationAttachment } from '../entities/consultation-attachment.type';

export abstract class ConsultationFilesRepository {
  abstract addNewFile(
    consultantId: string,
    attachment: Blob,
    isVoiceMessage: boolean,
  ): Observable<ConsultationAttachment>;

  abstract getConsultationAttachments(
    consultantId: string,
  ): Observable<readonly ConsultationAttachment[]>;
}

import { inject, Injectable } from '@angular/core';

import { map, type Observable, switchMap, take } from 'rxjs';

import { UserRepository } from '../../repositories/user.repository';
import { LanguageApplicationService } from '../../../profile';
import { AuthenticationRepository } from '../../repositories/authentication.repository';

@Injectable({
  providedIn: 'root',
})
export class RegistrationApplicationService {
  #userRepository: UserRepository = inject(UserRepository);

  #authRepository: AuthenticationRepository = inject(AuthenticationRepository);

  #languageApplicationService: LanguageApplicationService = inject(
    LanguageApplicationService,
  );

  validateEmailActivateToken(urlToken: string): Observable<void> {
    return this.#authRepository.validateEmailActivateToken(urlToken);
  }

  resendEmailValidationLink(urlToken: string): Observable<void> {
    return this.#authRepository.resendEmailValidationLink(urlToken);
  }

  register(data: {
    readonly email: string;
    readonly username: string;
    readonly password: string;
    readonly passwordConfirmation: string;
    readonly privacy: boolean;
    readonly termsOfUse: boolean;
  }): Observable<void> {
    return this.#languageApplicationService.getDefaultLanguage().pipe(
      take(1),
      map((lang: string) => ({
        ...data,
        locale: lang,
      })),
      switchMap((modifiedData) => this.#userRepository.register(modifiedData)),
    );
  }
}

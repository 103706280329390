import { Observable } from 'rxjs';

import { AuthenticationTokens } from '../entities/authentication-tokens';

export abstract class AuthenticationRepository {
  abstract saveAuthenticationTokens(
    tokens: AuthenticationTokens,
  ): Observable<void>;

  abstract deleteAuthenticationTokens(): Observable<void>;

  abstract getAuthenticationTokens(): Observable<AuthenticationTokens>;

  abstract validateEmailActivateToken(token: string): Observable<void>;

  abstract validatePasswordResetToken(token: string): Observable<void>;

  abstract sendNewPasswordRequest(mail: string): Observable<void>;

  abstract resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Observable<void>;

  abstract resendEmailValidationLink(expiredToken: string): Observable<void>;
}

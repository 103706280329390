import { DeleteTokenError } from './delete-token-error.type';

export class ApproveDeleteTokenError implements DeleteTokenError {
  readonly tokenForAccountDeleteHasExpired!: boolean;

  readonly tokenForAccountDeleteNotFound!: boolean;

  constructor(error: DeleteTokenError) {
    Object.assign(this, error);
  }
}

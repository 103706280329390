import { inject, Injectable } from '@angular/core';

import { catchError, map, type Observable, of } from 'rxjs';

import { AuthenticationRepository } from '../../repositories/authentication.repository';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApplicationService {
  readonly #authenticationRepository: AuthenticationRepository = inject(
    AuthenticationRepository,
  );

  areTokensValid(): Observable<boolean> {
    return this.#authenticationRepository.getAuthenticationTokens().pipe(
      map((): boolean => true),
      catchError((): Observable<false> => {
        return of(false);
      }),
    );
  }

  resetPassword(
    password: string,
    confirmPassword: string,
    token: string,
  ): Observable<void> {
    return this.#authenticationRepository.resetPassword(
      password,
      confirmPassword,
      token,
    );
  }

  sendNewPasswordRequest(email: string): Observable<void> {
    return this.#authenticationRepository.sendNewPasswordRequest(email);
  }

  validatePasswordResetToken(token: string): Observable<void> {
    return this.#authenticationRepository.validatePasswordResetToken(token);
  }
}

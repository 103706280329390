import type { EmailValidationErrors } from './email-validation-errors.type';

export class ResetPasswordInitErrors implements EmailValidationErrors {
  readonly emailAddressContainsInvalidString!: boolean;

  readonly emailAddressContainsNotAllowedCharacters!: boolean;

  readonly emailAddressContainsSpacesOrTabs!: boolean;

  readonly emailAddressDnsCheckFailed!: boolean;

  readonly emailAddressIsAlreadyInUse!: boolean;

  readonly emailAddressIsEmpty!: boolean;

  readonly emailAddressIsNotAString!: boolean;

  readonly emailAddressIsNotRfcCompliant!: boolean;

  readonly emailAddressIsTooLong!: boolean;

  readonly emailAddressIsTooShort!: boolean;

  constructor(errors: EmailValidationErrors) {
    Object.assign(this, errors);
  }
}

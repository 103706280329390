export function compareDates(
  dateA: Date | null,
  dateB: Date | null,
): 1 | 0 | -1 {
  const dateAValid: boolean = dateA instanceof Date && !isNaN(dateA.getTime());
  const dateBValid: boolean = dateB instanceof Date && !isNaN(dateB.getTime());

  // if dateA is NOT provided but dateB -> dateA < dateB
  if (!dateAValid && dateBValid) {
    return -1;
  }
  // if dateA is provided but dateB is NOT -> dateA > dateB
  else if (dateAValid && !dateBValid) {
    return 1;
  }
  // if dateA AND dateB are NOT provided -> dateA == dateB
  else if (!dateAValid && !dateBValid) {
    return 0;
  }
  // if dateA is more in the present than dateB -> dateA > dateB
  else if (dateA!.getTime() > dateB!.getTime()) {
    return 1;
  }
  // if dateA is in the past compared to dateB -> dateA < dateB
  else if (dateA!.getTime() < dateB!.getTime()) {
    return -1;
  }

  // dateA and dateB point at the identical date (highly unlikely, but whatever)
  return 0;
}
